import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import styled from 'styled-components'

import InputGroup from './InputGroup'
import StyledLabel from './StyledLabel'
import OptionalWrapper from './OptionalWrapper'
import StyledErrorMessage from './StyledErrorMessage'

export const StyledSelect = styled.select`
  appearance: none;
  width: 100%;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  background-color: #FFF;
  border: 1px solid ${props => props.haserror ? '#c03333' : '#c0c0c0'};
  border-radius: 0;
  font-family: ${props => props.theme.fonts.serif};
  font-size: 1rem;
  outline: 0;
`

const SelectInput = ({
  label,
  ...props
}) => {
  const [field, meta] = useField(props)

  return (
    <InputGroup>
      <StyledLabel htmlFor={props.id || props.name}>
        {label}:

        {!props.required && <OptionalWrapper>{` ${'Optional'}`}</OptionalWrapper>}
      </StyledLabel>

      <StyledSelect
        aria-required={props.required}
        {...field}
        {...props}
        id={props.id || props.name}
      />

      {meta.touched && meta.error ? (
        <StyledErrorMessage>{meta.error}</StyledErrorMessage>
      ) : null}
    </InputGroup>
  )
}

SelectInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  translationKey: PropTypes.string,
  type: PropTypes.string,
  autoComplete: PropTypes.string,
  required: PropTypes.bool,
  errors: PropTypes.object,
  touched: PropTypes.object,
  onBlur: PropTypes.func,
}

SelectInput.defaultProps = {
  id: null,
  translationKey: null,
  type: 'text',
  autoComplete: null,
  required: false,
  onBlur: null,
}

export default SelectInput
