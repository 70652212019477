export const checkEmail = (email) => {
  return new Promise((resolve, reject) => {
    fetch('/.netlify/functions/check-email', {
      method: 'POST',
      body: JSON.stringify({
        email: email,
      }),
    })
      .then(res => res.json())
      .then(json => {
        resolve(json)
      })
      .catch(error => reject(error))
  })
}
