import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import { withTranslation } from 'react-i18next'
import axios from 'axios'
import { Flex } from 'rebass/styled-components'
import * as countryList from 'country-list'

import TextInput from '../TextInput'
import SelectInput from '../SelectInput'
import getValidationSchema from './getValidationSchema'
import {
  StyledSubmitButton,
  SuccessMessage,
  ErrorMessage,
  SuggestEmailAddressButton,
  FormDelimter,
} from './styles'
import { checkEmail } from '../../utils/checkEmail'

const SignUpForm = ({ t, apikey, apiURL }) => {
  const [submission, setSubmission] = useState({
    success: false,
    error: false,
    sending: false,
  })
  const [email, setEmail] = useState({
    didYouMean: null,
    fixed: false,
  })
  const countries = countryList.getNames().sort((a, b) => {
    if (a > b) {
      return 1
    }
    if (a < b) {
      return -1
    }
    return 0
  })

  const signupUrl = `${apiURL}/api/v1/agents/sign-up?apikey=${apikey}`

  const sendMail = (values, { resetForm }) => {
    setSubmission({
      ...submission,
      sending: true,
      success: false,
    })

    axios
      .post(signupUrl, {
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        phone: values.phone,
        address1: values.address1,
        address2: values.address2 || '',
        city: values.city,
        country: values.country,
      })
      .then((response) => {
        if (response) {
          if (response.status === 200) {
            setSubmission({
              sending: false,
              error: false,
              success: true,
            })
            resetForm()
          }
        }
      })
      .catch((error) => {
        setSubmission({
          error: error,
          success: false,
          sending: false,
        })
      })
  }

  const handleEmailBlur = (insertedEmail) => {
    if (
      typeof insertedEmail === 'string' &&
      insertedEmail !== '' &&
      !email.fixed
    ) {
      checkEmail(insertedEmail).then((res) => {
        if (res.did_you_mean) {
          setEmail({ ...email, didYouMean: res.did_you_mean })
        }
      })
    }
  }

  const validationSchema = getValidationSchema(t)
  const submitDefaultLabel = t('signup:form.submit.defaultLabel', {
    defaultValue: 'Sign up',
  })
  const submitSendingLabel = t('signup:form.submit.sendingLabel', {
    defaultValue: 'Sending…',
  })

  return (
    <Formik
      initialValues={{
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        address1: '',
        address2: '',
        city: '',
        country: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values, formik) => {
        sendMail(values, formik)
        setEmail({ didYouMean: null, fixed: false })
      }}
    >
      {({ errors, setFieldValue, isValid, dirty }) => (
        <Form>
          <Flex width="100%" flexDirection="column">
            <TextInput
              name="firstname"
              label={t('signup:form.firstname.label', {
                defaultValue: 'First name',
              })}
              autoComplete="given-name"
              required
            />

            <TextInput
              name="lastname"
              label={t('signup:form.lastname.label', {
                defaultValue: 'Last name',
              })}
              autoComplete="family-name"
              required
            />

            <TextInput
              name="email"
              label={t('signup:form.email.label', {
                defaultValue: 'E-mail',
              })}
              type="email"
              autoComplete="email"
              required
              onBlur={(e) => {
                !errors.email && handleEmailBlur(e.target.value)
              }}
            />

            {!!email.didYouMean && !email.fixed && (
              <SuggestEmailAddressButton
                type="button"
                onClick={() => {
                  // Set valie in formik
                  setFieldValue('email', email.didYouMean)
                  // Reset suggested email
                  // and if email was fixed, do not send another check email request
                  setEmail({ didYouMean: null, fixed: true })
                }}
              >
                {`Did you mean ${email.didYouMean}?`}
              </SuggestEmailAddressButton>
            )}

            <TextInput
              name="phone"
              label={t('signup:form.phone.label', {
                defaultValue: 'Phone',
              })}
              type="tel"
              autoComplete="tel"
              required
            />

            <FormDelimter>
              {t('signup:form.shipping-address.title', {
                defaultValue: 'Shipping address',
              })}
            </FormDelimter>

            <TextInput
              name="address1"
              label={t('signup:form.address1.label', {
                defaultValue: 'Address Line 1',
              })}
              autoComplete="address-line1"
              required
            />

            <TextInput
              name="address2"
              label={t('signup:form.address2.label', {
                defaultValue: 'Address Line 2',
              })}
              autoComplete="address-line2"
            />

            <TextInput
              name="city"
              label={t('signup:form.city.label', {
                defaultValue: 'City',
              })}
              required
            />

            <SelectInput
              name="country"
              label={t('signup:form.country.label', {
                defaultValue: 'Country',
              })}
              required
            >
              <option value="" disabled>
                {t('signup:form.country.prompt', {
                  defaultValue: 'Select country',
                })}
              </option>
              {countries.map((country) => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
            </SelectInput>

            <StyledSubmitButton
              type="submit"
              disabled={submission.sending || !dirty || !isValid}
            >
              {!submission.sending ? submitDefaultLabel : submitSendingLabel}
            </StyledSubmitButton>

            {submission.success && (
              <SuccessMessage>
                {t('signup:form.state.success', {
                  defaultValue: 'Sign up successful!',
                })}
              </SuccessMessage>
            )}

            {submission.error && (
              <ErrorMessage>
                {t('signup:form.state.error', {
                  defaultValue: 'Sign up failed! Please try again.',
                })}
              </ErrorMessage>
            )}
          </Flex>
        </Form>
      )}
    </Formik>
  )
}

SignUpForm.propTypes = {
  t: PropTypes.func.isRequired,
  apikey: PropTypes.string.isRequired,
  apiURL: PropTypes.string.isRequired,
}

export default withTranslation('signup')(SignUpForm)
