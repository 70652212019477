import * as Yup from 'yup'

const getValidationSchema = (t) => {
  return Yup.object().shape({
    firstname: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required(
        t('signup:form.firstname.required', {
          defaultValue: 'First name is required',
        }),
      ),
    lastname: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required(
        t('signup:form.lastname.required', {
          defaultValue: 'Last name is required',
        }),
      ),
    email: Yup.string()
      .email(
        t('signup:form.email.invalid', {
          defaultValue: 'Invalid email address',
        }),
      )
      .required(
        t('signup:form.email.required', {
          defaultValue: 'E-mail is required',
        }),
      ),
    phone: Yup.string().required(
      t('signup:form.phone.required', {
        defaultValue: 'Phone is required',
      }),
    ),
    address1: Yup.string().required(
      t('signup:form.address1.required', {
        defaultValue: 'Address Line 1 is required',
      }),
    ),
    address2: Yup.string(),
    city: Yup.string().required(
      t('signup:form.city.required', {
        defaultValue: 'City is required',
      }),
    ),
    country: Yup.string().required(
      t('signup:form.country.required', {
        defaultValue: 'Country is required',
      }),
    ),
  })
}

export default getValidationSchema
